import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthTokenProvider } from "hooks/useAuthToken";
import { BookmarkedFundsProvider } from "hooks/useBookmarkedFunds";
import { Toaster } from "react-hot-toast";
import { Route, Switch } from "wouter";

import Login from "./components/Login";
import Screener from "./components/Screener";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
    },
  },
});
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthTokenProvider>
        <BookmarkedFundsProvider>
          <Toaster />
          <Switch>
            <Route path="/screener" component={Screener} />
            <Route path="" component={Login} />
          </Switch>
        </BookmarkedFundsProvider>
      </AuthTokenProvider>
    </QueryClientProvider>
  );
}

export default App;
