import Arrow from "components/shared/Arrow";

import styles from "./Select.module.css";
export default function Select({ label, options, value, onChange }) {
  return (
    <label className={styles.label}>
      <span
        className={`${styles.labeltext} ${value === "" ? "" : styles.visible}`}
      >
        {label}
      </span>
      <select className={styles.select} value={value} onChange={onChange}>
        <option value="">{label}</option>
        {options.map(([val, optionLabel]) => (
          <option key={val} value={val}>
            {optionLabel}
          </option>
        ))}
      </select>
      <Arrow className={styles.arrow} />
    </label>
  );
}
