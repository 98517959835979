import { useQuery } from "@tanstack/react-query";
import { screenerRequest } from "api/screenerRequest";
import { useAuthToken } from "hooks/useAuthToken";
import { useSelectedFunds } from "hooks/useSelectedFunds";
import { useRef } from "react";
import { A11yDialog } from "react-a11y-dialog";

import ComparisonTable from "./ComparisonTable";
import MultifundChart from "./MultifundChart";
import styles from "./Selected.module.css";

const modalClasses = {
  container: styles.dialogContainer,
  overlay: styles.dialogOverlay,
  dialog: styles.dialogContent,
  title: styles.dialogTitle,
  closeButton: styles.dialogCloseButton,
};

export default function Selected() {
  const { selectedFundIds } = useSelectedFunds();
  const { authToken } = useAuthToken();
  const dialog = useRef();

  const { data, fetchStatus } = useQuery({
    queryKey: ["selected-funds-list", authToken, selectedFundIds.join(" ")],
    enabled: !!authToken && selectedFundIds.length > 0,
    keepPreviousData: true,
    queryFn: () =>
      screenerRequest({
        authToken,
        params: {
          page: 1,
          pageSize: 100,
          term: selectedFundIds.join(" "),
        },
      }),
    placeholderData: {},
    select: (data) =>
      (data?.rows || []).reduce(
        (funds, fund) => ({
          ...funds,
          [fund.secId]: fund,
        }),
        {}
      ),
  });

  if (selectedFundIds.length === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.button}>
        <button onClick={() => dialog.current.show()} type="button">
          Compare {selectedFundIds.length} Selected Funds
        </button>
      </div>

      <A11yDialog
        id="compare-selected-funds-dialog"
        dialogRef={(instance) => (dialog.current = instance)}
        title="Fund Comparison"
        classNames={modalClasses}
        closeButtonContent={<Close />}
      >
        <div className={styles.comparison}>
          {data && (
            <>
              <section className={styles.tableContainer}>
                <ComparisonTable
                  funds={selectedFundIds.map(
                    (secId) => data[secId] || { secId }
                  )}
                  loading={fetchStatus === "fetching"}
                />
              </section>
              <h4>Monthly Returns Comparison</h4>
              <MultifundChart
                funds={selectedFundIds.map((secId) => data[secId] || { secId })}
              />
            </>
          )}
        </div>
      </A11yDialog>
    </>
  );
}

function Close() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6L12 12L18 6"
        stroke="#6C1CF6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 18L12 12L6 18"
        stroke="#6C1CF6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
