import classNames from "classnames";
import Arrow from "components/shared/Arrow";

import styles from "./PaginationControls.module.css";
export default function PaginationControls({
  previousPage,
  page,
  nextPage,
  maxPage,
}) {
  return (
    <nav className={styles.paginationControls}>
      <button
        type="button"
        disabled={!previousPage}
        onClick={previousPage}
        aria-label="Previous page"
        className={styles.button}
      >
        <Arrow />
      </button>
      <div
        style={{ textAlign: "center", verticalAlign: "center" }}
      >{`Page ${page} of ${maxPage}`}</div>
      <button
        type="button"
        disabled={!nextPage}
        onClick={nextPage}
        aria-label="Next page"
        className={classNames(styles.button, styles.rightButton)}
      >
        <Arrow />
      </button>
    </nav>
  );
}
