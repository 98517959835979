import { createContext, useContext, useEffect, useState } from "react";
const AuthTokenContext = createContext(null);

const TOKEN_KEY = "useAuthToken-token";

export const AuthTokenProvider = ({ children, token }) => {
  const storedVal = localStorage.getItem(TOKEN_KEY);
  const [authToken, setAuthToken] = useState(token || storedVal || null);
  useEffect(() => {
    if (storedVal === authToken) {
      return;
    } else if (authToken) {
      localStorage.setItem(TOKEN_KEY, authToken);
    } else {
      localStorage.removeItem(TOKEN_KEY);
    }
  }, [authToken, storedVal]);

  return (
    <AuthTokenContext.Provider value={{ authToken, setAuthToken }}>
      {children}
    </AuthTokenContext.Provider>
  );
};

export const useAuthToken = () => {
  const context = useContext(AuthTokenContext);
  if (context === null) {
    throw new Error("useAuthToken must be used within a AuthTokenProvider");
  }
  return { loggedIn: !!context.authToken, ...context };
};
