import { useQuery } from "@tanstack/react-query";
import { screenerRequest } from "api/screenerRequest";
import { useAuthToken } from "hooks/useAuthToken";
import { useBookmarkedFunds } from "hooks/useBookmarkedFunds";
import { usePagination } from "hooks/usePagination";
import { useSortBy } from "hooks/useSortBy";
import { useState } from "react";
import { toast } from "react-hot-toast";

import { filtersToQuery } from "./Filters";
import PaginationControls from "./PaginationControls";
import Table from "./Table";

export default function BookmarkTable({ filters, visible }) {
  const { setAuthToken, authToken } = useAuthToken();
  const [total, setTotal] = useState(0);
  const { maxPage, nextPage, page, pageSize, setPageSize, previousPage } =
    usePagination(total);
  const { toggleSortBy, sortKey, sortDirection, sortString } = useSortBy();
  const { bookmarkedFundIds } = useBookmarkedFunds();
  const { data, fetchStatus } = useQuery({
    queryKey: [
      "screener",
      authToken,
      page,
      pageSize,
      filtersToQuery(filters),
      sortString,
      bookmarkedFundIds,
    ],
    enabled: !!authToken,
    queryFn: () =>
      bookmarkedFundIds.length === 0
        ? Promise.resolve({ rows: [] })
        : screenerRequest({
            authToken,
            params: {
              page,
              pageSize,
              filters: filtersToQuery(filters),
              sortOrder: sortString || "name asc",
              term: bookmarkedFundIds.join(" "),
            },
          }),
    placeholderData: { rows: [] },
    keepPreviousData: true,
    onSuccess: (data) => {
      if (data.total !== total) {
        setTotal(data.total);
      }
    },
    onError: (e) => {
      if (e.response.status === 401) {
        setAuthToken(null);
        toast.error("Your session has expired, please sign in again.");
      }
    },
  });
  if (!visible) {
    return null;
  }

  return (
    <Table
      isLoading={
        !data || (data.rows.length === 0 && fetchStatus === "fetching")
      }
      visible={true}
      rows={data?.rows || []}
      toggleSortBy={toggleSortBy}
      sortKey={sortKey}
      sortDirection={sortDirection}
      paginationControls={
        <PaginationControls
          nextPage={nextPage}
          page={page}
          pageSize={pageSize}
          setPageSize={setPageSize}
          previousPage={previousPage}
          maxPage={maxPage}
        />
      }
    />
  );
}
