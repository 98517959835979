import RelativeTimeCell from "components/Selected/DateCell";
import MultifundChart from "components/Selected/MultifundChart";
import format from "date-fns/format";

import styles from "./Details.module.css";

const DATA = [
  [
    "Fund manager",
    (_, details) => {
      const manager = details.ManagerList[0];
      return manager ? `${manager.GivenName} ${manager.FamilyName}` : "-";
    },
  ],
  [
    "Manager tenure",
    (fund) => <RelativeTimeCell yearsAgo={fund.managerTenure} />,
  ],
  [
    "Fund inception",
    (fund) =>
      fund.inceptionDate
        ? format(new Date(fund.inceptionDate), "MMMM yyyy")
        : "-",
  ],
  ["Risk rating", (fund) => fund.riskRating || "-"],
  [
    "12 month yield",
    (fund) =>
      fund.yield_M12 ? `${Math.round(fund.yield_M12 * 100) / 100}%` : "-",
  ],
  ["Last Close Price (USD)", (fund) => fund.closePrice || "-"],
];

export default function Details({ fund, details, className }) {
  if (!details) {
    return (
      <tr className={styles.loadingRow}>
        <td colSpan={10}>
          <div className={styles.progressContainer}>
            <progress aria-label="Content loading…" />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr className={className}>
      <td />
      <td colSpan={8}>
        <div>
          <h4 className={styles.header}>Fund Details</h4>
          <dl className={styles.datalist}>
            {DATA.map(([label, extractor]) => (
              <div className={styles.datum} key={label}>
                <dt>{label}</dt>
                <dd>{extractor(fund, details)}</dd>
              </div>
            ))}
          </dl>

          <h4 className={styles.header}>Monthly Returns Graph</h4>
          <MultifundChart funds={[fund]} />
        </div>
      </td>
      <td />
    </tr>
  );
}
