import logo from "img/logo.svg";

import styles from "./Header.module.css";

export default function Header() {
  return (
    <header className={styles.header}>
      <img src={logo} alt="Bank Monroe" />
      <h1 className={styles.title}>Investment Fund Screener Tool</h1>
    </header>
  );
}
