import { useQuery } from "@tanstack/react-query";
import { screenerRequest } from "api/screenerRequest";
import Selected from "components/Selected";
import Page from "components/shared/Page";
import { useAuthToken } from "hooks/useAuthToken";
import useDebouncedState from "hooks/useDebouncedState";
import { usePagination } from "hooks/usePagination";
import { SelectedFundsProvider } from "hooks/useSelectedFunds";
import { useSortBy } from "hooks/useSortBy";
import searchGlass from "img/search.svg";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Redirect } from "wouter";

import BookmarkTable from "./BookmarkTable";
import BookmarkToggle from "./BookmarkToggle";
import Filters, { filtersToQuery } from "./Filters";
import PaginationControls from "./PaginationControls";
import styles from "./Screener.module.css";
import Table from "./Table";

export default function Screener() {
  const { setAuthToken, authToken } = useAuthToken();
  const [total, setTotal] = useState(0);
  const { maxPage, nextPage, page, pageSize, setPageSize, previousPage } =
    usePagination(total);
  const [filters, setFilters] = useState({});
  const [searchterm, setSearchterm, debouncedSearchterm] = useDebouncedState(
    "",
    500
  );
  const { toggleSortBy, sortKey, sortDirection, sortString } = useSortBy();
  const { data, fetchStatus } = useQuery({
    queryKey: [
      "screener",
      authToken,
      page,
      pageSize,
      filtersToQuery(filters),
      sortString,
      debouncedSearchterm,
    ],
    enabled: !!authToken,
    queryFn: () =>
      screenerRequest({
        authToken,
        params: {
          page,
          pageSize,
          filters: filtersToQuery(filters),
          sortOrder: sortString || "name asc",
          ...(debouncedSearchterm === "" ? {} : { term: debouncedSearchterm }),
        },
      }),
    placeholderData: { rows: [] },
    onSuccess: (data) => {
      if (data.total !== total) {
        setTotal(data.total);
      }
    },
    onError: (e) => {
      if (e.response.status === 401) {
        setAuthToken(null);
        toast.error("Your session has expired, please sign in again.");
      }
    },
  });
  const [showOnlyBookmarks, setShowOnlyBookmarks] = useState(false);

  return (
    <SelectedFundsProvider>
      <Page>
        <section className={styles.searchArea}>
          <div className={styles.searchBar}>
            <img className={styles.searchIcon} src={searchGlass} alt="" />
            <input
              type="text"
              value={searchterm}
              onChange={(e) => setSearchterm(e.target.value)}
              className={styles.searchInput}
              aria-label="Fund search term"
              placeholder="Search funds…"
            />
          </div>
        </section>
        <div className={styles.screener}>
          <section className={styles.data}>
            {!authToken && <Redirect to={"/"} />}

            <div className={styles.filterBar}>
              <Filters filters={filters} setFilters={setFilters} />

              <div className={styles.bookmarkToggle}>
                <BookmarkToggle
                  onToggle={() => setShowOnlyBookmarks((s) => !s)}
                  toggleState={showOnlyBookmarks}
                />
              </div>
            </div>

            <BookmarkTable visible={showOnlyBookmarks} filters={filters} />
            <Table
              visible={!showOnlyBookmarks}
              isLoading={
                !data || (data.rows.length === 0 && fetchStatus === "fetching")
              }
              rows={data?.rows || []}
              toggleSortBy={toggleSortBy}
              sortKey={sortKey}
              sortDirection={sortDirection}
              paginationControls={
                <PaginationControls
                  nextPage={nextPage}
                  page={page}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  previousPage={previousPage}
                  maxPage={maxPage}
                />
              }
            />
            <Selected />
          </section>
        </div>
        <div className={styles.logoutFooter}>
          <button
            type="button"
            onClick={() => {
              setAuthToken(null);
            }}
          >
            Logout
          </button>
        </div>
      </Page>
    </SelectedFundsProvider>
  );
}
