import styles from "./Stars.module.css";

const STAR_RANGE = [0, 5];
const num = (starRatingM255) => {
  const [min, max] = STAR_RANGE;
  return Math.min(Math.max(+starRatingM255, min), max) || 0;
};

export default function Stars({ stars }) {
  if (!stars) return "-";
  return (
    <div className={styles.stars} role="img" alt={`${num(stars)} stars`}>
      <span className={styles.filled}>{"★".repeat(num(stars))}</span>
      <span className={styles.empty}>{"★".repeat(5 - num(stars))}</span>
    </div>
  );
}
