import { useBookmarkedFunds } from "hooks/useBookmarkedFunds";
import { useSelectedFunds } from "hooks/useSelectedFunds";

import Row, { EmptyRow, HeaderRow } from "./Row";
import styles from "./Table.module.css";
export default function Table({
  rows,
  isLoading,
  paginationControls,
  toggleSortBy,
  sortKey,
  sortDirection,
  visible,
}) {
  const { selectedFunds, toggleFund } = useSelectedFunds();
  const { bookmarkedFunds, toggleFund: toggleBookmark } = useBookmarkedFunds();
  if (!visible) {
    return null;
  }
  return (
    <section className={styles.container}>
      <table className={styles.table}>
        <thead>
          <HeaderRow
            toggleSortBy={toggleSortBy}
            sortKey={sortKey}
            sortDirection={sortDirection}
          />
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={10}>
                <div className={styles.progressContainer}>
                  <progress aria-label="Content loading…" />
                </div>
              </td>
            </tr>
          ) : rows.length === 0 ? (
            <EmptyRow />
          ) : (
            rows.map((row) => (
              <Row
                key={row.secId}
                row={row}
                selected={selectedFunds[row.secId]}
                toggleFund={toggleFund}
                bookmarked={bookmarkedFunds[row.secId]}
                toggleBookmark={toggleBookmark}
              />
            ))
          )}
        </tbody>
      </table>
      {paginationControls}
    </section>
  );
}
