import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const CHART_POINTS = [
  ["ReturnM1", "1 Month"],
  ["ReturnM3", "3 Month"],
  ["ReturnM6", "6 Month"],
  ["ReturnM12", "1 Year"],
  ["ReturnM36", "3 Year"],
  ["ReturnM60", "5 Year"],
  ["ReturnM120", "10 Year"],
];
const chartData = (funds) => {
  return [{}]
    .concat(
      CHART_POINTS.map(([key, label]) => ({
        label,
        ...funds.reduce(
          (accum, fund) => ({ ...accum, [fund.symbol]: fund[key] }),
          {}
        ),
      }))
    )
    .concat([{}]);
};

const COLORS = [
  "#F07A8A",
  "#3155EC",
  "#11C75D",
  "#FFBA69",
  "#4DFFDF",
  "#C44EFC",
  "#2AB1C9",
  "#F7A85E",
  "#76D7C4",
  "#EF5285",
  "#e2a0b5",
  "#791dda",
];

export default function MultifundChart({ funds }) {
  return (
    <ResponsiveContainer width={"100%"} height={370}>
      <LineChart
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
        data={chartData(funds)}
      >
        <Legend iconType="circle" verticalAlign="top" height={36} />
        <CartesianGrid vertical={false} />
        {funds.map((fund, i) => (
          <Line
            key={fund.secId}
            type="linear"
            isAnimationActive={false}
            name={fund.symbol}
            dataKey={fund.symbol}
            unit="%"
            stroke={COLORS[i]}
            strokeWidth="2px"
          />
        ))}

        <Tooltip />
        <XAxis dataKey="label" axisLine={false} tickLine={false} />
        <YAxis unit="%" axisLine={false} tickLine={false} />
      </LineChart>
    </ResponsiveContainer>
  );
}
