import classNames from "classnames";
import Select from "components/shared/Select";

import styles from "./Filters.module.css";
export const filtersToQuery = (filters) =>
  Object.keys(filters)
    .filter((filter) => filters[filter])
    .map((filter) => `${filter}:${filters[filter]}`)
    .join("|");

const FILTERS = [
  {
    key: "StarRatingM255",
    label: "Rating",
    options: [
      ["EQ:5", "★★★★★"],
      ["EQ:4", "★★★★"],
      ["EQ:3", "★★★"],
      ["EQ:2", "★★"],
      ["EQ:1", "★"],
    ],
  },
  {
    key: "expenseRatio",
    label: "Expense Ratio",
    options: [
      ["LT:1", "< 1%"],
      ["BTW:1:1.5", "1% - 1.5%"],
      ["BTW:1.5:2", "1.5% - 2%"],
      ["BTW:2:2.5", "2% - 2.5%"],
      ["BTW:2.5:3", "2.5% - 3%"],
      ["GT:3", "> 4%"],
    ],
  },
  {
    label: "1 Year Return",
    key: "ReturnM12",
    options: [
      ["LT:0", "Negative"],
      ["BTW:1:5", "1% - 5%"],
      ["BTW:5:10", "5% - 10%"],
      ["BTW:10:15", "10% - 15%"],
      ["BTW:15:20", "15% - 20%"],
      ["GT:20", "> 20%"],
    ],
  },
  {
    label: "3 Year Return",
    key: "ReturnM36",
    options: [
      ["LT:0", "Negative"],
      ["BTW:1:5", "1% - 5%"],
      ["BTW:5:10", "5% - 10%"],
      ["BTW:10:15", "10% - 15%"],
      ["BTW:15:20", "15% - 20%"],
      ["GT:20", "> 20%"],
    ],
  },
  {
    label: "5 Year Return",
    key: "ReturnM60",
    options: [
      ["LT:0", "Negative"],
      ["BTW:1:5", "1% - 5%"],
      ["BTW:5:10", "5% - 10%"],
      ["BTW:10:15", "10% - 15%"],
      ["BTW:15:20", "15% - 20%"],
      ["GT:20", "> 20%"],
    ],
  },
];

export default function Filters({ filters, setFilters }) {
  const filtersSelected = !!Object.keys(filters).length;
  return (
    <div className={styles.container}>
      <div className={styles.labelRow}>
        <h4 className={styles.label}>Filters</h4>
        <button
          className={classNames(
            styles.button,
            filtersSelected && styles.visible
          )}
          type="button"
          onClick={() => setFilters({})}
        >
          Reset Filters
        </button>
      </div>
      <div className={styles.filters}>
        {FILTERS.map(({ key, label, options }) => (
          <Select
            key={key}
            label={label}
            options={options}
            value={filters[key] || ""}
            onChange={(e) => setFilters({ ...filters, [key]: e.target.value })}
          />
        ))}
      </div>
    </div>
  );
}
