import { useReducer } from "react";

function reducer(state, action) {
  switch (action.type) {
    case "increment-page": {
      return {
        ...state,
        page: state.page + 1,
      };
    }
    case "decrement-page": {
      return {
        ...state,
        page: state.page - 1,
      };
    }
    case "change-page-size": {
      return {
        ...state,
        page: 1,
        pageSize: action.pageSize,
      };
    }
    case "reset": {
      return {
        ...state,
        page: 1,
      };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

export const usePagination = (totalItems) => {
  const [{ page, pageSize }, dispatch] = useReducer(reducer, {
    page: 1,
    pageSize: 100,
  });

  const minPage = 1;
  const maxPage = totalItems ? Math.ceil(totalItems / pageSize) : 0;

  return {
    page,
    pageSize,
    maxPage,
    reset: () => dispatch({ type: "reset" }),
    nextPage:
      page < maxPage ? () => dispatch({ type: "increment-page" }) : null,
    previousPage:
      page > minPage ? () => dispatch({ type: "decrement-page" }) : null,
    setPageSize: (newSize) => {
      dispatch({ type: "change-page-size", pageSize: newSize });
    },
  };
};
