import axios from "axios";

export async function loginRequest({ username, password }) {
  const { data } = await axios
    .post(
      process.env.REACT_APP_TOKEN_URL,
      {},
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        auth: { username, password },
      }
    )
    .catch((error) => {
      throw new Error(
        error?.response?.status === 401
          ? "Incorrect username or password."
          : "Something went wrong."
      );
    });
  return data["access_token"];
}
