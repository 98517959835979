import axios from "axios";

const COLS = [
  "secId",
  "name",
  "closePrice",
  "yield_M12",
  "ReturnD1",
  "ReturnW1",
  "ReturnM1",
  "ReturnM3",
  "ReturnM6",
  "ReturnM0",
  "ReturnM12",
  "ReturnM36",
  "ReturnM60",
  "ReturnM120",
  "ongoingCharge",
  "morningstarSustainabilityRating",
  "categoryName",
  "expenseRatio",
  "symbol",
  "quantitativeRating",
  "analystRatingScale",
  "starRatingM255",
  "sustainabilityRank",
  "inceptionDate",
  "riskRating",
  "managerTenure",
  "categoryName",
];

const DEFAULT_PARAMS = {
  page: 1,
  pageSize: 20,
  languageId: "en-US",
  currencyId: "USD",
  sortOrder: "name asc",
  version: 1,
  universeIds: "FOUSA$$ALL",
  outputType: "json",
  securityDataPoints: COLS.join(","),
};

export async function screenerRequest({ authToken, params }) {
  const { data } = await axios.get(process.env.REACT_APP_SCREENER_URL, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${authToken}`,
    },
    params: {
      ...DEFAULT_PARAMS,
      ...params,
    },
  });
  return data;
}
