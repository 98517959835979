import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

const BookmarkedFundsContext = createContext(null);

function bookmarkedFundsReducer(state, action) {
  switch (action.type) {
    case "toggle_fund": {
      const { [action.fundId]: isBookmarked, ...rest } = state;

      return isBookmarked ? { ...rest } : { ...rest, [action.fundId]: true };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export const BOOKMARKED_FUNDS_KEY = "useBookmarkedFunds-funds";

export const BookmarkedFundsProvider = ({ children }) => {
  const initial = localStorage.getItem(BOOKMARKED_FUNDS_KEY);

  const [bookmarkedFunds, dispatch] = useReducer(
    bookmarkedFundsReducer,
    initial ? JSON.parse(initial) : {}
  );

  useEffect(() => {
    localStorage.setItem(BOOKMARKED_FUNDS_KEY, JSON.stringify(bookmarkedFunds));
  }, [bookmarkedFunds]);

  const bookmarkedFundIds = useMemo(
    () => Object.keys(bookmarkedFunds),
    [bookmarkedFunds]
  );

  return (
    <BookmarkedFundsContext.Provider
      value={{
        bookmarkedFundIds,
        bookmarkedFunds,
        toggleFund: (fundId) => dispatch({ type: "toggle_fund", fundId }),
      }}
    >
      {children}
    </BookmarkedFundsContext.Provider>
  );
};

export const useBookmarkedFunds = () => {
  const context = useContext(BookmarkedFundsContext);
  if (context === null) {
    throw new Error(
      "useBookmarkedFunds must be used within a BookmarkedFundsProvider"
    );
  }
  return { ...context };
};
