import { useForm } from "react-hook-form";

import styles from "./Form.module.css";

export default function Form({ onSubmit }) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const submitHandler = (data) =>
    onSubmit(data).catch((error) => {
      setError("root.serverError", {
        message: error.message,
      });
    });

  return (
    <form className={styles.form} onSubmit={handleSubmit(submitHandler)}>
      <label>
        Username
        <input
          aria-invalid={errors.username ? true : null}
          {...register("username", { required: "required" })}
        />
        {errors.username && (
          <small role="alert">{errors.username.message}</small>
        )}
      </label>

      <label>
        Password
        <input
          type="password"
          aria-invalid={errors.password ? true : null}
          {...register("password", { required: "required" })}
        />
        {errors.password && (
          <small role="alert">{errors.password.message}</small>
        )}
      </label>

      {errors.root && (
        <p style={{ color: "#C62828" }} role="alert">
          {errors.root.serverError.message}
        </p>
      )}
      <button type="submit" aria-busy={isSubmitting} disabled={isSubmitting}>
        Sign in
      </button>
    </form>
  );
}
