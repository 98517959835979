import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

const SelectedFundsContext = createContext(null);

function selectedFundsReducer(state, action) {
  switch (action.type) {
    case "toggle_fund": {
      const { [action.fundId]: isSelected, ...rest } = state;

      return isSelected ? { ...rest } : { ...rest, [action.fundId]: true };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export const SELECTED_FUNDS_KEY = "useSelectedFunds-funds";

export const SelectedFundsProvider = ({ children }) => {
  const initial = localStorage.getItem(SELECTED_FUNDS_KEY);

  const [selectedFunds, dispatch] = useReducer(
    selectedFundsReducer,
    initial ? JSON.parse(initial) : {}
  );

  useEffect(() => {
    localStorage.setItem(SELECTED_FUNDS_KEY, JSON.stringify(selectedFunds));
  }, [selectedFunds]);

  const selectedFundIds = useMemo(
    () => Object.keys(selectedFunds),
    [selectedFunds]
  );

  return (
    <SelectedFundsContext.Provider
      value={{
        selectedFundIds,
        selectedFunds,
        toggleFund: (fundId) => dispatch({ type: "toggle_fund", fundId }),
      }}
    >
      {children}
    </SelectedFundsContext.Provider>
  );
};

export const useSelectedFunds = () => {
  const context = useContext(SelectedFundsContext);
  if (context === null) {
    throw new Error(
      "useSelectedFunds must be used within a SelectedFundsProvider"
    );
  }
  return { ...context };
};
