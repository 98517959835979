import { useMutation } from "@tanstack/react-query";
import { loginRequest } from "api/loginRequest";
import Page from "components/shared/Page";
import { useAuthToken } from "hooks/useAuthToken";
import { Redirect } from "wouter";

import Form from "./Form";
import styles from "./Login.module.css";
export default function Login() {
  const { setAuthToken, authToken } = useAuthToken();

  const login = useMutation({
    mutationFn: (credentials) => {
      return loginRequest(credentials);
    },
    onSuccess: (accessToken) => setAuthToken(accessToken),
  });

  return (
    <Page low>
      <div className={styles.container}>
        <main className={styles.login}>
          {!!authToken && <Redirect to={"/screener"} />}
          <h2>Sign in</h2>
          <Form onSubmit={login.mutateAsync} />
        </main>
      </div>
    </Page>
  );
}
