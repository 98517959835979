import { useQuery } from "@tanstack/react-query";
import { detailsRequest } from "api/detailsRequest";
import classNames from "classnames";
import Arrow from "components/shared/Arrow";
import { useAuthToken } from "hooks/useAuthToken";
import bookmarkEmpty from "img/bookmark-empty.svg";
import bookmarkFilled from "img/bookmark-filled.svg";
import { useState } from "react";
import formatPercent from "support/formatPercent";

import Details from "./Details";
import styles from "./Row.module.css";
import Stars from "./Stars";

const COLUMNS = [
  [
    "Fund Name",
    "name",
    (row) => (
      <>
        <b>{row.symbol}</b> {row.name}
      </>
    ),
  ],
  [
    "Morningstar Rating™",
    "starRatingM255",
    (row) => <Stars stars={row.starRatingM255} />,
  ],
  ["Morningstar Category", "categoryName", (row) => row.categoryName],
  ["Expense Ratio", "expenseRatio", (row) => formatPercent(row.expenseRatio)],
  ["1 year return", "ReturnM12", (row) => formatPercent(row.ReturnM12)],
  ["3 year return", "ReturnM36", (row) => formatPercent(row.ReturnM36)],
  ["5 year return", "ReturnM60", (row) => formatPercent(row.ReturnM60)],
];
const arrowFor = (direction) => (direction === "asc" ? "↑" : "↓");

export function HeaderRow({ toggleSortBy, sortKey, sortDirection }) {
  return (
    <tr>
      <th scope="col" />
      {COLUMNS.map(([label, key, _]) => (
        <th scope="col" key={key} onClick={() => toggleSortBy(key)}>
          {label}
          {sortKey === key ? arrowFor(sortDirection) : null}
        </th>
      ))}
      <th scope="col" />
      <th scope="col" />
    </tr>
  );
}
export function EmptyRow() {
  return (
    <tr>
      <td colSpan={10}>
        <div className={styles.emptyRowMessage}>No Funds</div>
      </td>
    </tr>
  );
}

export default function Row({
  row,
  selected,
  toggleFund,
  bookmarked,
  toggleBookmark,
}) {
  const { authToken } = useAuthToken();
  const [showDetails, setShowDetails] = useState(false);
  const { data, fetchStatus } = useQuery({
    queryKey: ["details", row.secId],
    enabled: showDetails && !!authToken,
    queryFn: () => detailsRequest({ authToken, secId: row.secId }),
    select: (data) => data[0],
  });

  return (
    <>
      <tr
        key={row.secId}
        className={classNames(showDetails && styles.expanded)}
      >
        <td>
          <input
            type="checkbox"
            checked={!!selected}
            onChange={() => toggleFund(row.secId)}
            name={row.secId}
          />
        </td>

        {COLUMNS.map(([_, key, extractor], i) =>
          key === "name" ? (
            <th key={key} scope="row">
              {extractor(row)}
            </th>
          ) : (
            <td key={key}>{extractor(row)}</td>
          )
        )}

        <td>
          <button
            className={styles.button}
            type="button"
            aria-busy={fetchStatus === "fetching"}
            onClick={() => setShowDetails((s) => !s)}
            aria-label={showDetails ? "Less" : "More"}
          >
            <Arrow
              className={classNames(
                styles.arrow,
                showDetails && styles.arrowup
              )}
            />
          </button>
        </td>

        <td>
          <button
            className={styles.bookmarkButton}
            type="button"
            onClick={() => toggleBookmark(row.secId)}
          >
            {bookmarked ? (
              <img src={bookmarkFilled} alt="Remove bookmark" />
            ) : (
              <img src={bookmarkEmpty} alt="Bookmark" />
            )}
          </button>
        </td>
      </tr>

      {showDetails && (
        <Details
          key={`${row.secId}-details`}
          fund={row}
          details={data}
          className={styles.details}
        />
      )}
    </>
  );
}
