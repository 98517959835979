import Stars from "components/Screener/Stars";
import { useSelectedFunds } from "hooks/useSelectedFunds";
import formatPercent from "support/formatPercent";

import styles from "./ComparisonTable.module.css";
import RelativeTimeCell from "./DateCell";
const ROWS = [
  ["Name", (fund) => fund.name],
  [
    "Manager Tenure",
    (fund) => <RelativeTimeCell yearsAgo={fund.managerTenure} />,
  ],
  ["Fund Inception", (fund) => <RelativeTimeCell date={fund.inceptionDate} />],
  ["Morningstar Rating™", (fund) => <Stars stars={fund.starRatingM255} />],
  ["Morningstar Category", (fund) => fund.categoryName],
  ["Expense Ratio", (fund) => `${fund.expenseRatio}%`],
  ["Risk Rating", (fund) => fund.riskRating],
  ["1 year return", (fund) => formatPercent(fund.ReturnM12)],
  ["3 year return", (fund) => formatPercent(fund.ReturnM36)],
  ["5 year return", (fund) => formatPercent(fund.ReturnM60)],
];

export default function ComparisonTable({ funds, loading }) {
  const { toggleFund } = useSelectedFunds();

  if (!funds) {
    return null;
  }

  return (
    <table className={styles.table} aria-busy={loading || undefined}>
      <thead>
        <tr>
          <th />
          {funds.map((fund) => (
            <th key={fund.secId}>
              <div className={styles.nameWrapper}>
                <input
                  type="checkbox"
                  checked={true}
                  onChange={() => toggleFund(fund.secId)}
                />

                <div className={styles.name}>{fund.symbol}</div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {ROWS.map(([label, extractor]) => (
          <tr key={label}>
            <th scope="row">{label}</th>
            {funds.map((fund) => (
              <td key={fund.secId}>{extractor(fund)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
