import { useReducer } from "react";

function sortByReducer(state, action) {
  switch (action.type) {
    case "toggle_sort": {
      if (state.key === action.key && state.direction === "desc") {
        return {};
      } else if (state.key === action.key) {
        return { key: action.key, direction: "desc" };
      } else {
        return { key: action.key, direction: "asc" };
      }
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export const useSortBy = () => {
  const [{ key, direction }, dispatch] = useReducer(sortByReducer, {});

  return {
    toggleSortBy: (newKey) => dispatch({ type: "toggle_sort", key: newKey }),
    sortKey: key,
    sortDirection: direction,
    sortString: key ? `${key} ${direction}` : undefined,
  };
};
