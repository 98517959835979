import classNames from "classnames";

import styles from "./BookmarkToggle.module.css";
export default function BookmarkToggle({ onToggle, toggleState }) {
  return (
    <button
      className={styles.button}
      role="switch"
      aria-checked={toggleState}
      onClick={onToggle}
    >
      <div className={classNames(styles.track, toggleState && styles.trackOn)}>
        <div className={styles.knob} />
      </div>
      <span>Show only bookmarks</span>
    </button>
  );
}
