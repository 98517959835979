import addDays from "date-fns/addDays";
import formatDuration from "date-fns/formatDuration";
import intervalToDuration from "date-fns/intervalToDuration";

export default function RelativeTimeCell({ date, yearsAgo }) {
  if (!date && !yearsAgo) {
    return "-";
  }
  const start = yearsAgo
    ? addDays(new Date(), yearsAgo * -365)
    : new Date(date || undefined);
  return formatDuration(
    intervalToDuration({
      start,
      end: new Date(),
    }),
    {
      format: ["years", "months"],
    }
  );
}
