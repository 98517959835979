import axios from "axios";

export async function detailsRequest({ authToken, secId }) {
  const { data } = await axios.get(
    `${process.env.REACT_APP_DETAILS_URL}/${secId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        viewId: "MFsnapshot",
        responseViewFormat: "json",
        idtype: "msid",
        languageId: "en-US",
        currencyId: "USD",
      },
    }
  );

  return data;
}
